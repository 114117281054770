<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="form" @submit.prevent="saveEmployee" class="custom-form">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" :elevation="1" height="30px" dark>
                                    <v-toolbar-title class="subtitle-2">Employee Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="4">Emp. ID</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        :value="$store.getters['employee/newCode']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Emp. Name</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model="employee.name"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="4">Father's Name</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model="employee.father_name"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="4">Mother's Name</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model="employee.mother_name"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Pre. Address</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model="employee.present_address"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Per. Address</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model="employee.permanent_address"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Department</v-col>
                                                <v-col cols="7">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        item-text="name"
                                                        item-value="id"
                                                        @focus="$store.dispatch('department/getDepartments')"
                                                        :loading="$store.getters['department/loading']"
                                                        :items="$store.getters['department/departments']"
                                                        v-model="selected.selectedDepartment"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn to="/department" target="_blank" class="ml-1" height="25px" dark small block color="primary">
                                                        <v-icon class="pt-1">mdi-plus</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Designation</v-col>
                                                <v-col cols="7">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        item-text="name"
                                                        item-value="id"
                                                        @focus="$store.dispatch('designation/getDesignations')"
                                                        :loading="$store.getters['designation/loading']"
                                                        :items="$store.getters['designation/designations']"
                                                        v-model="selected.selectedDesignation"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn to="/designation" target="_blank" class="ml-1" height="25px" dark small block color="primary">
                                                        <v-icon class="pt-1">mdi-plus</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Joining Date</v-col>
                                                <v-col cols="8">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            hide-details
                                                            v-model="employee.joining_date"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            autocomplete="off"
                                                        >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                        </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="employee.joining_date"
                                                            @input="menu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Gender</v-col>
                                                <v-col cols="8">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        :items="['Male', 'Female']"
                                                        v-model="selected.selectedGender"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Date of Birth</v-col>
                                                <v-col cols="8">
                                                    <v-menu
                                                        v-model="menu2"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="employee.date_of_birth"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                autocomplete="off"
                                                            >
                                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="employee.date_of_birth"
                                                            @input="menu2 = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Contact No</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model="employee.contact_number"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">E-mail</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="employee.email"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Salary Range</v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model="employee.salary_range"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Marital Status</v-col>
                                                <v-col cols="8">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        :items="['Married', 'Unmarried']"
                                                        v-model="selected.selectedMaritalStatus"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4" class="text-right pr-2">Image</v-col>
                                                <v-col cols="8">
                                                    <v-file-input 
                                                        show-size 
                                                        outlined 
                                                        dense
                                                        hide-details
                                                        prepend-icon=""
                                                        prepend-inner-icon="mdi-camera"
                                                        class="e-image"
                                                        ref="employee_image"
                                                        @change="onChangeImage"
                                                    >
                                                    </v-file-input>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4" class="text-right pr-2">Status</v-col>
                                                <v-col cols="8">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        item-text="text"
                                                        item-value="val"
                                                        :items="statusList"
                                                        v-model="selected.selectedStatus"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4"></v-col>
                                                <v-col cols="8">
                                                    <v-row dense>
                                                         <v-col cols="6">
                                                            <v-btn @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="30px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="employeeHeaders"
                    :loading="$store.getters['employee/loading']"
                    :items="$store.getters['employee/employees']"
                    :search="searchEmployee"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Employee List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Employee"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchEmployee"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <span v-if="item.status">Active</span>
                        <span v-if="!item.status">Inactive</span>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="showEmployeeProfile(item)" color="primary" v-on="on">mdi-eye</v-icon>
							</template>
							<span>View</span>
						</v-tooltip>
						<v-tooltip v-if="userType != 'user'" bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editEmployee(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip v-if="userType != 'user'" bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteEmployee"></delete-confirm>
        <employee-profile :employee="employeeProfile" ref="employeeProfile"></employee-profile>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'
import EmployeeProfile from '@/components/EmployeeProfile'

export default {
    name: 'Employee',
    components: {
        DeleteConfirm,
        EmployeeProfile,
    },
    data: ()=> ({
        menu: false,
        menu2: false,
        statusList: [
            {val: 1, text: 'Active'}, 
            {val: 0, text: 'Inactive'}
        ],
        employeeHeaders: [
            { text: 'Emp. ID', value: 'code' },
            { text: 'Name', value: 'name' },
            { text: 'Designation', value: 'designation.name' },
            { text: 'Department', value: 'department.name' },
            { text: 'Joining Date', value: 'joining_date' },
            { text: 'Contact No', value: 'contact_number' },
            { text: 'Status', value: 'status' },
            { text: 'Action', value: 'action' },
        ],
        searchEmployee: '',
        employee_code: '',
        employee: {
            name: '',
            father_name: '',
            mother_name: '',
            present_address: '',
            permanent_address: '',
            joining_date: new Date().toISOString().substr(0, 10),
            gender: '',
            date_of_birth: new Date().toISOString().substr(0, 10),
            contact_number: '',
            email: '',
            salary_range: '',
        },
        selected: {
            selectedDepartment: null,
            selectedDesignation: null,
            selectedGender: null,
            selectedMaritalStatus: null,
            selectedStatus: null,
            selectedImage: null,
        },
        waitingForSave: false,
        employeeEditId: null,
        employeeDeleteId: null,
        employeeProfile: {},
        userType: ''
    }),
    created() {
        this.$store.dispatch('employee/getEmployees')
        this.$store.dispatch('employee/getEmployeeCode')
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type
    },
    methods: {
        onChangeImage(e) {
            if (e == undefined || e == null) return
            let allowedTypes = ['image/jpg', 'image/jpeg', 'image/png']
            if(allowedTypes.indexOf(e.type) == -1) {
                this.$refs.employee_image.reset()
                this.$store.dispatch('snackbar/error', 'Invalid image type. Supported types : jpg, png, jpeg')
                return
            }
            this.selected.selectedImage = e
        },

        async saveEmployee() {
            let valid = this.$refs.form.validate()
            if (!valid) return
            if (this.employee.email && !(/(.+)@(.+){2,}\.(.+){2,}/).test(this.employee.email)) {
                this.$store.dispatch('snackbar/error', 'Email is not valid')
                return
            }
            
            let fd = new FormData

            Object.keys(this.employee).map(k => {
                fd.append(k, this.employee[k])
            })

            if (this.selected.selectedImage != null) {
                fd.append('image', this.selected.selectedImage);
            }

            fd.append('department_id', this.selected.selectedDepartment.id)
            fd.append('designation_id', this.selected.selectedDesignation.id)
            fd.append('gender', this.selected.selectedGender)
            fd.append('marital_status', this.selected.selectedMaritalStatus)
            fd.append('status', this.selected.selectedStatus.val)

            this.waitingForSave = true

            if (this.employeeEditId == null) {
                let res = await this.$store.dispatch('employee/saveEmployee', fd)
                if (res) this.resetForm()
            } else {
                fd.append('id', this.employeeEditId);
                let res = await this.$store.dispatch('employee/updateEmployee', fd)
                if (res) {
                    this.employeeEditId = null
                    this.resetForm()
                }
            }

            this.waitingForSave = false
            this.$store.dispatch('employee/getEmployees')
        },

        resetForm() {
            Object.keys(this.employee).map(k => this.employee[k] = '')
            Object.keys(this.selected).map(k => this.selected[k] = null)
            this.$refs.form.resetValidation()
            this.$refs.employee_image.reset()
            this.employeeEditId = null
            this.employee.joining_date = new Date().toISOString().substr(0, 10);
            this.employee.date_of_birth = new Date().toISOString().substr(0, 10);
        },

        editEmployee(employee) {
            Object.keys(this.employee).map(k => {
                if (employee[k] != null) this.employee[k] = employee[k]
            })

            this.selected.selectedDepartment = employee.department_id
            this.selected.selectedDesignation = employee.designation
            this.selected.selectedDepartment = employee.department
            this.selected.selectedGender = employee.gender
            this.selected.selectedMaritalStatus = employee.marital_status

            this.statusList.map(status => {
                if (status.val == employee.status)
                this.selected.selectedStatus = status
            })

            this.employeeEditId = employee.id
        },

        openDeleteDialog(id) {
            this.employeeDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteEmployee() {
            this.$store.dispatch('employee/deleteEmployee', {id: this.employeeDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.employeeDeleteId = null
        },

        showEmployeeProfile(profile) {
            this.employeeProfile = profile
            this.$refs.employeeProfile.dialog = true
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px;
    }
    .v-file-input .v-text-field__slot {
        min-height: 27px !important;
    }
    .e-image {
        .v-input__prepend-inner {
            margin-top: 0 !important;
        }
    }
</style>